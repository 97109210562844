<section class="positon-relative" id="portfolio">
    <div class="section-styles">
        <div class="portfolio-title">
            <div class="title-and-border">
                <div class="border-left"></div>
                <h2>Portfolio</h2>
                <div class="border-right"></div>
            </div>
            <span>Entdecken Sie hier eine Auswahl meiner Arbeiten - Interagieren Sie mit Projekten, um meine Fähigkeiten in Aktion zu sehen.</span>
        </div>
    
        <div data-aos="fade-right" class="portfolio-content" *ngFor="let portfolio of portfolio; let i = index"  [class.reverse]="i % 2 === 1">
            <img class="portfolio-left" src="./assets/img/portfolio/{{ portfolio.image }}.svg" alt="">
            <div class="portfolio-right">
                <h3> {{ portfolio.name }} </h3>
                <h4> {{ portfolio.skills }} </h4>
                <span> {{ portfolio.description }} </span>
                <div class="portfolio-buttons">
                    <a target="_blank" href="{{ portfolio.test }}"><button class="primary-btn">Live test</button></a>
                    <a target="_blank" href="{{ portfolio.gitHub }}"><button class="secondary-btn">GitHub</button></a>
                </div>
            </div>
        </div>
    </div>

    <img class="portfolio-shape" src="./assets/img/portfolio-shape.svg" alt="">
    <img class="portfolio-shape2" src="./assets/img/portfolio-shape2.svg" alt="">

</section>
