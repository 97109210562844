<footer>
    <div class="desktop-footer">
        <div class="footer-left">
            <img src="./assets/img/logo.svg" alt="Grischa Logo">
            <a href="impressum">Impressum</a>
        </div>
    
        <span>&#169; Grischa Tänzer 2024</span>
    
        <div class="footer-right">
            <a href="https://github.com/GrischaTDev" target="_blank"><img src="./assets/img/github-icon.svg" alt=""></a>
            <a href="mailto:grischa.taenzer@gmail.com"><img src="./assets/img/email-icon.svg" alt=""></a>    
            <a href="https://www.linkedin.com/in/grischa-taenzer/" target="_blank"><img src="./assets/img/linkedin-icon.svg" alt=""></a>
        </div>
    </div>

    <div class="mobile-footer">
        <img src="./assets/img/logo.svg" alt="Grischa Logo">
        <span>&#169; Grischa Tänzer 2024</span>
        <div class="footer-right">
            <a href="https://github.com/GrischaTDev" target="_blank"><img src="./assets/img/github-icon.svg" alt=""></a>
            <a href="mailto:grischa.taenzer@gmail.com"><img src="./assets/img/email-icon.svg" alt=""></a>    
            <a href="https://www.linkedin.com/in/grischa-taenzer/" target="_blank"><img src="./assets/img/linkedin-icon.svg" alt=""></a>
        </div>
        <a href="impressum">Impressum</a>
    </div>

    <img (click)="backToStart()" class="go-up-btn" src="./assets/img/go-up.svg" alt="Go up">
</footer>