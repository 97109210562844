<section class="hero">
    <div class="person">
        <img class="person-shape" src="./assets/img/person-shape.svg">
        <img src="./assets/img/grischa-foto.svg" alt="Foto von mir">
    </div>

    <div class="name">
        <div class="name-text">
            <span class="side-text">Ich bin</span>
            <div class="name-title">
                <h1>Grischa Tänzer</h1>
                <h2>Frontend Entwickler</h2>
            </div>
        </div>
        <a href="#contact"><button class="primary-btn">Lass uns reden!</button></a>
    </div>

    <img class="hero-shape" src="./assets/img/hero-shape.svg">

    <div class="info">
        <div class="social">
            <div class="social-border"></div>
            <div class="icon-list">
                <a href="https://github.com/GrischaTDev" target="_blank"><img src="./assets/img/github-icon.svg" alt=""></a>
                <a href="#contact"><img src="./assets/img/email-icon.svg" alt=""></a>    
                <a href="https://www.linkedin.com/in/grischa-taenzer/" target="_blank"><img src="./assets/img/linkedin-icon.svg" alt=""></a>
            </div>
            <a href="mailto:grischa.taenzer@gmail.com">grischa.taenzer&#64;gmail.com</a>
        </div>
        <img class="scroll-down" src="./assets/img/scroll-down.svg" alt="">
    </div>
</section>