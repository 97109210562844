<header>
    <img src="./assets/img/logo.svg" alt="Grischa Logo">

    <nav [ngClass]="{'mobile-nav': mobileMenu}">
        <a (click)="closeMobileMenu()" href="#aboute-me">Über mich</a>
        <a (click)="closeMobileMenu()" href="#skills">Skills</a>
        <a (click)="closeMobileMenu()" href="#portfolio">Portfolio</a>
        <a (click)="closeMobileMenu()" href="#contact">Kontakt</a>
    </nav>

    <img (click)="openMobileMenu()" class="mobile-menu" [src]="burgerMenu" alt="Grischa Logo">
</header>