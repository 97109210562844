<section class="positon-relative" id="aboute-me">
    <div class="section-styles">
        <div class="about-me-text" data-aos="fade-right">
            <h2>Über mich</h2>
            <span>Hallo! Mein Name ist Grischa Tänzer, ich bin 35 Jahre alt und habe Erfahrung als Frontend-Entwickler in der Erstellung von Web-Applikationen. Meine Fachkenntnisse habe ich durch zwei Weiterbildungen erworben. Im Jahr 2016 habe ich ein Fernstudium zum Web-Designer an der ILS in Hamburg erfolgreich abgeschlossen. Anschließend begann ich 2023 eine Weiterbildung zum Frontend-Entwickler bei der TÜV-zertifizierten Developer Akademie GmbH.</span>
            <div class="icon-text">
                <img src="./assets/img/about-icon1.svg" alt="">
                <span>Es macht mir großen Spaß, kreative und effektive Lösungen für die Gestaltung digitaler Erlebnisse zu finden. Die Vielfalt und Dynamik der Programmierung inspiriert mich jeden Tag aufs Neue.</span>
            </div>
            <div class="icon-text">
                <img src="./assets/img/about-icon2.svg" alt="">
                <span>Ich bin diszipliniert und verfüge über ein logisches Verständnis, das mir hilft, auch mit komplexeren Problemen umzugehen. Sehen Sie sich gerne meine Projekte an, um einen Eindruck davon zu bekommen, woran ich gearbeitet habe.</span>
            </div>
        </div>
        <div class="kreis-foto" data-aos="fade-left">
            <img src="./assets/img/grischa-kreis.svg" alt="">
            <div class="border"></div>
        </div>
    </div>

    <img class="about-shape" src="./assets/img/about-me-shape.svg" alt="">
</section>

