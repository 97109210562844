<section class="positon-relative" id="skills">
    <div class="section-styles">
        <div class="skills-container" data-aos="fade-right">
            <div class="skill" *ngFor="let skill of skills">
                <img [src]="'assets/img/skills/' + skill.icon + '.svg'" [alt]="skill.name" />
                <div> {{ skill.name }}</div>
            </div>
            <div class="skills-btn-mobile">
                <a href="#contact"><button class="primary-btn">Kontakt aufnehmen</button></a>
            </div>
        </div>
    
        <div class="my-skills-right" data-aos="fade-left">
            <div class="skills-title">
                <h2>Meine Skills</h2>
                <div class="skills-border"></div>
            </div>
            <span>Ich habe meine Fähigkeiten im Bereich der Frontend-Entwicklung ausgebaut, die es mir ermöglicht haben,
                verschiedene reale Projekte zu erstellen.</span>
            <div class="skills-btn">
                <a href="#contact"><button class="primary-btn">Kontakt aufnehmen</button></a>
            </div>
        </div>
    </div>

    <img class="skills-shape" src="./assets/img/skills-shape.svg" alt="">
</section>