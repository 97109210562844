<section class="section-styles" id="contact">

    <div class="contact-title" data-aos="fade-right">
        <div class="contact-title-and-border">
            <div class="contact-border-left"></div>
            <h2>Kontakt</h2>
        </div>
        <div class="description">
            <h3>Haben Sie ein Problem zu lösen?</h3>
            <span>Kontaktieren Sie mich über dieses Formular. Ich freue mich darauf, von Ihnen zu hören, Ihre Ideen zu kennen und mit meiner Arbeit zu Ihren Projekten beizutragen.</span>
            <span>Brauchen Sie einen Frontend-Entwickler? Kontaktieren Sie mich!</span>
        </div>
    </div>

    <form data-aos="fade-left" class="contact-form" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm" ngNativeValidate>
        <input [(ngModel)]="contactData.name" type="text" name="name" placeholder="Ihr Name" id="name" required>
        <input [(ngModel)]="contactData.email" type="email" name="email" placeholder="Ihre E-Mail" id="email" required>
        <textarea [(ngModel)]="contactData.message" type="text" name="message" placeholder="Ihre Nachricht" id="textarea" cols="30" rows="9" required></textarea>
        <div class="checkbox-container">
            <div (click)="checkboxConfirm()" class="checkbox">
                <img [src]="checkboxSrc">
            </div>
            <span>Ich habe die Datenschutzerklärung gelesen und stimme der Verarbeitung meiner Daten wie beschrieben zu.</span>
        </div>
        <div class="checkbox-msg" [ngClass]="{'d-none': !checkboxDisplay}">Bestätigen Sie die Datenschutzerklärung!</div>
        <button class="primary-btn">Nachricht senden :)</button>
    </form>

    <div class="formSendMsg" [ngClass]="{'d-none': !formSend}">
        <h2>Ihre Nachricht wurde versendet<span>!</span></h2>
    </div>

</section>